import React, { useEffect, useContext } from "react"
import { graphql } from "gatsby"
import { withPreview } from "gatsby-source-prismic"

// Querys
import { StandardPageQuery } from "../GraphQl/queries" // eslint-disable-line
// Context
import { SiteContext } from "../context"

// Components
import Layout from "../layouts/index"
import SEO from "../components/seo"
import { SliceZone } from "../components/slices"

const IndexPage = ({ data }) => {
  const { setReverseHeader } = useContext(SiteContext)
  const {
    page_title,
    meta_description,
    keywords,
    reverse_header,
    body,
  } = data.prismicStandardPage.data
  const keywordArray = keywords ? keywords.split(",") : []

  useEffect(() => {
    const isHeaderRev = reverse_header === "true"
    if (isHeaderRev) {
      setReverseHeader(true)
    } else {
      setReverseHeader(false)
    }
  }, [reverse_header, setReverseHeader])

  return (
    <Layout>
      <SEO
        title={page_title}
        description={meta_description}
        keywords={keywordArray}
      />
      {body && <SliceZone slices={body} />}
    </Layout>
  )
}

export const pageQuery = graphql`
  {
    prismicStandardPage(tags: { eq: "Homepage" }) {
      ...PageQuery
    }
  }
`

export default withPreview(IndexPage)
